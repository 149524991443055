
import { HomePage } from '@/components/pages'
import { Meta } from '@/layouts'
import { MainLayout } from '@/layouts/main-layout'
import { cmsBlock, homeApi, listCinema, movie } from '@/services'
import { TNextPageWithLayout } from '@/types/layout'

const Index: TNextPageWithLayout = (res: any) => {
  return (
    <>
      <Meta title='Cinestar - Hệ thống rạp chiếu phim giá rẻ, hiện đại bậc nhất' description='Hệ thống rạp chiếu phim Cinestar phục vụ khán giả với những thước phim điện ảnh chất lượng, dịch vụ tốt nhất với giá vé chỉ từ 45.000đ. Đặt vé ngay hôm nay để nhận được những ưu đãi bất ngờ từ Cinestar.' canonical={process.env.NEXT_PUBLIC_DEPLOY_URL} />
      <HomePage res={res} />
    </>
  )
}

// export const getServerSideProps = async () => {
export const getStaticProps = async () => {
  type Tbanner = {
    banner_id: number,
    name: string,
    media: string
  }
  const dataRequestMovie = {
    id_Country: '0',
    id_MovieFormats: '0',
    id_MovieType: '0',
    id_Movie: "0",
    // status: '2', // Lấy theo phim đang chiếu
  }
  // lấy phim đang chiếu
  const listMovie: TMovie[] = await movie.getList({ ...dataRequestMovie, status: '2' }).then((res: any) => {
    // trả về mảng sắp xếp theo sortorder
    return res?.sort((a: TMovie, b: TMovie) => Number(a?.sortorder) - Number(b?.sortorder))
  })

  // lấy phim sắp chiếu
  const listComingMovie: TMovie[] = await movie.getList({ ...dataRequestMovie, status: '1' }).then((res: any) => {
    // trả về mảng sắp xếp theo sortorder
    return res?.sort((a: TMovie, b: TMovie) => Number(a?.sortorder) - Number(b?.sortorder))
  })
  const variable = {
    identifiers: ["cac-dich-vu-giai-tri-khac", 'chuong-trinh-thanh-vien']
  }
  const ortherService_en: any = await cmsBlock.getCmsBlock(variable, "en").then((res: any) => {
    return res?.cmsBlocks?.items
  })
  const ortherService_vn: any = await cmsBlock.getCmsBlock(variable, "vn").then((res: any) => {
    return res?.cmsBlocks?.items
  })
  const listPromotion: Tbanner[] = await homeApi.getSlider('khuyen-mai').then((res: any) => {
    return res?.Slider?.items?.[0]?.Banner?.items
  })
  const listBannerPromotion: Tbanner[] = await homeApi.getSlider('banner-khuyen-mai').then((res: any) => {
    return res?.Slider?.items?.[0]?.Banner?.items
  })
  const listCinemas = await listCinema.getListCinema().then((res: any) => {
    return res?.cinemas
  })
  const footerContactCms = await cmsBlock.getCmsBlock({ identifiers: ["home-page-contact-left", "home-page-contact-form-top"] }).then((res: any) => {
    return res?.cmsBlocks?.items
  })

  return {
    props: {
      res: {
        listMovie,
        listComingMovie,
        listCinemas
      },
      ortherService: {
        ortherService_en: (ortherService_en?.find((item: any) => item?.identifier === 'cac-dich-vu-giai-tri-khac')?.content?.replaceAll("&lt;", "<")?.replaceAll("&gt;", ">") || null) as string | null,
        ortherService_vn: (ortherService_vn?.find((item: any) => item?.identifier === 'cac-dich-vu-giai-tri-khac')?.content?.replaceAll("&lt;", "<")?.replaceAll("&gt;", ">") || null) as string | null,
      },
      memberShip: {
        memberShip_en: (ortherService_en?.find((item: any) => item?.identifier === 'chuong-trinh-thanh-vien')?.content?.replaceAll("&lt;", "<")?.replaceAll("&gt;", ">") || null) as string | null,
        memberShip_vn: (ortherService_vn?.find((item: any) => item?.identifier === 'chuong-trinh-thanh-vien')?.content?.replaceAll("&lt;", "<")?.replaceAll("&gt;", ">") || null) as string | null,
      },
      listPromotion: {
        listPromotion: listPromotion || [],
        listBannerPromotion: listBannerPromotion || []
      },
      footerContactCms: {
        left: footerContactCms?.find((item: any) => item?.identifier === 'home-page-contact-left')?.content || null,
        top: footerContactCms?.find((item: any) => item?.identifier === 'home-page-contact-form-top')?.content || null
      }
    },
    revalidate: 20,
  }
}


Index.Layout = MainLayout

export default Index
